/* eslint-disable react/prop-types */

/**
 * @file Temporary events page
 * @author Alwyn Tan
 */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { LoaderIcon } from 'react-hot-toast'
import { navigate } from 'gatsby'
import eventsImage from '../images/events.jpg'
import logo from '../images/logo.png'
import { GET_EVENT_URL } from '../constants'
import Layout from '../components/templates/Layout'
import SEO from '../components/simple/SEO'
import featuredEvents from '../constants/featured-events.json'
import Button from '../components/simple/Button'
import PlainCard from '../components/compound/Cards/PlainCard'
import Footer from '../components/simple/Footer'

const Container = styled.div`
  *.mobile {
    display: none;
  }

  // mobile sizes
  @media only screen and (max-width: 768px) {
    *.mobile {
      display: block;
    }

    *.desktop {
      display: none;
    }
  }
`

const Logo = styled.img`
  height: 50px;
  object-fit: contain;
  position: absolute;
  filter: invert();
  margin-top: 20px;
  margin-left: 30px;
`

const Header = styled.div`
  width: 100%;
  padding: 150px 50px;
  background-image: url(${eventsImage});
  background-size: cover;
  color: white;

  // mobile sizes
  @media only screen and (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;

    > h1 {
      font-size: 3.3em;
    }
  }
`

const Title = styled.h1`
  padding-top: 50px;
  text-align: center;
`

const EventsList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 100px;
`

const Row = styled.div`
  display: flex;
  width: 100%;
  max-width: 1050px;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 30px;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};

  // mobile sizes
  @media only screen and (max-width: 768px) {
    button {
      margin-top: 10px;
    }

    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }
`

const CardContents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // mobile sizes
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > img {
      margin-bottom: 10px;
    }

    > * {
      margin-left: 0 !important;
      margin-right: 0 !important;
      text-align: center;
    }

    > *:not(:first-child) {
      padding-top: 10px;
    }
  }
`

const ActiveImage = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 10px;
  margin-right: 50px;
  object-fit: cover;
`

const EventRow = ({ id, title, city, date, disabled }) => {
  return (
    <Row disabled={disabled}>
      <h1 className="desktop" style={{ paddingRight: 50, width: 200 }}>
        {date}
      </h1>
      <div className="desktop" style={{ flex: 0.9 }}>
        <h2>{city}</h2>
        <p>{title}</p>
      </div>
      <h2 className="mobile">{`${date}, ${city}`}</h2>
      <p className="mobile">{title}</p>
      <div>
        <Button onClick={() => navigate(`/app/events/${id}`)}>Tickets</Button>
      </div>
    </Row>
  )
}

const ActiveEventRow = ({ id, title, city, date, about, picture }) => {
  return (
    <Row>
      <PlainCard>
        <CardContents>
          <ActiveImage src={picture} />
          <div style={{ flex: 1 }}>
            <h1 className="desktop">{`${date}, ${city}`}</h1>
            <h2 className="mobile">{`${date}, ${city}`}</h2>
            <h2 style={{ color: '#434343', marginTop: 5 }} className="desktop">
              {title}
            </h2>
            <h3 style={{ color: '#434343', marginTop: 5 }} className="mobile">
              {title}
            </h3>
            <p
              style={{
                marginTop: 10,
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                textOverflow: 'ellipsis',
              }}
            >
              {about}
            </p>
          </div>
          <div style={{ marginLeft: 50 }}>
            <Button onClick={() => navigate(`/app/events/${id}`)}>
              Tickets
            </Button>
          </div>
        </CardContents>
      </PlainCard>
    </Row>
  )
}

const Events = () => {
  const [loadedEvents, setLoadedEvents] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // retrieve the events based off the featured ids
    const loadAllEvents = async () => {
      const promises = []
      const temp = []

      const loadEvent = async e => {
        try {
          const { event } = await fetch(`${GET_EVENT_URL}/${e.id}`).then(j =>
            j.json()
          )

          if (event) {
            const disabled =
              event.seatsLeft <= 0 || dayjs(event.startDate).isBefore(dayjs())
            temp.push({ ...event, city: e.city, disabled })
          }
        } catch (err) {
          console.error(err)
        }
      }

      for (const e of featuredEvents) promises.push(loadEvent(e))

      await Promise.all(promises)
      temp.sort((a, b) =>
        dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? -1 : 1
      )

      setLoadedEvents(temp)
      setLoading(false)
    }

    loadAllEvents()
  }, [])

  const renderFeaturedEvents = () =>
    loadedEvents.map((e, index) => {
      const isActive =
        (!e.disabled && index === 0) ||
        (!e.disabled && index > 0 && loadedEvents[index - 1].disabled)

      if (isActive)
        return (
          <ActiveEventRow
            disabled={e.disabled}
            picture={e.picture}
            key={e.id}
            id={e.id}
            title={e.title}
            city={e.city}
            date={dayjs(e.startDate).format('MMM DD')}
            about={e.about}
          />
        )

      return (
        <EventRow
          disabled={e.disabled}
          key={e.id}
          id={e.id}
          title={e.title}
          city={e.city}
          date={dayjs(e.startDate).format('MMM DD')}
          about={e.about}
        />
      )
    })

  return (
    <Layout hideHeader>
      <Container>
        <SEO title="Public Events" />
        <a href="/">
          <Logo src={logo} />
        </a>
        <Header>
          <h1 className="desktop">Great Food Better Company</h1>
          <h1 className="mobile">
            Great Food
            <br />
            Better Company
          </h1>
          <h3>We made it through ‘Rona. Time to celebrate.</h3>
        </Header>
        <Title>2021 featured Events</Title>
        <EventsList>
          {loading && (
            <LoaderIcon style={{ height: 40, width: 40, paddingBottom: 20 }} />
          )}
          {renderFeaturedEvents()}
        </EventsList>
      </Container>
      <Footer />
    </Layout>
  )
}

Events.propTypes = {}

export default Events
